.prothamacontact{
     
    height:  380px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/5N5mpJ28/krsihngagar-lifespace.jpg);
    background-attachment: fixed;
    color: white;
    
    justify-content: center;
    align-items: center;

  }

  .conatcttextcolor h1{
    color: #EC1D25
  }

  .colortext{
    color: #690000;
  }