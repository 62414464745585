.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a {
  text-decoration: none !important;
  
  
}

.colortext{
  color: rgb(17, 104, 114);
}

.colorback h4{
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}



 .container{
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}



.btn-grad42 {
  background-image: linear-gradient(to right, #580a2c 0%, #012632  51%, #164964  100%);
  margin: 0px;
  padding: 6px 18px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 3px;
  display: block;
}

.btn-grad42:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.bhkbutton{
  background-color: rgb(81, 11, 11); 
  border: none;
  color: white;
  padding: 6px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
}

.colornewtext{
  color: #034e6e;
}


.flatkrishnagarnadianew{
  background-color: #edfaff;
}

.colorconectivity{
  color: #034e6e;
}