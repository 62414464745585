.prothama{
    background-color: rgb(231, 238, 248);
}


.flatkrishnagar {
    background-color: #EC1D25; /* Green */
    border: none;
    color: #ffffff;
    padding: 8px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 9px;
  }
  
  .flatkrishnagarnadia {
    background-color: white; 
    color: black; 
    border: 2px solid #EC1D25;
  }

  .flatkrishnagar:hover {
    background-color: #EC1D25;
    color: white;
  }

.textcolor h5 {
    color: #EC1D25;
}


.colortextnew{
  color: #a16e0f;
}