.colorback{
    background-color: rgb(104, 7, 55);
    padding: 2px;
}

.prothama_life-space{
    height:  380px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/JzVc4zbX/flat.jpg);
    background-attachment: fixed;
    color: white;  
    align-items: center;
}



.formbutton {
    background-color: #232323; /* Green */
    border: none;
    color: white;
    padding: 7px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .buttoncallflats1 {
    background-color: white; 
    color: black; 
    border: 2px solid #494949;
  }

  .formbutton:hover {
    background-color: #EC1D25;
    color: white;
  }



  * {box-sizing: border-box}

  /* Container needed to position the overlay. Adjust the width as needed */
  .prothamalifespace {
    position: relative;
  
  }
  
  /* Make the image to responsive */
  .image10 {
    display: block;
    width: 100%;
    height: auto;
  }
  
  /* The overlay effect - lays on top of the container and over the image */
  .overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(205, 2, 2, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }
  
  /* When you mouse over the container, fade in the overlay title */
  .prothamalifespace:hover .overlay {
    opacity: 1;
  }

  .blink { 
    animation: blink-animation 1s infinite; 
  } 
    
  @keyframes blink-animation { 
    0% { 
      opacity: 1; 
    } 
    50% { 
      opacity: 0; 
    } 
    100% { 
      opacity: 1; 
    } 
  }


  * {box-sizing: border-box}

  /* Container needed to position the overlay. Adjust the width as needed */
  .hoverimage1 {
    position: relative;
  
  }
  
  /* Make the image to responsive */
  .image2 {
    display: block;
    width: 100%;
    height: auto;
  }
  /* The overlay effect - lays on top of the container and over the image */
.overlay1 {
  position: absolute;
  bottom: 0;
  background: rgb(20, 20, 21);
  background: rgba(24, 25, 25, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  border-radius: 0px;
}

/* When you mouse over the container, fade in the overlay title */
.hoverimage1:hover .overlay1 {
  opacity: 2;
}

.newbackhome{
background-color: #0036b3;
padding: 8px;
color: #f1f1f1;
}

.colortext{
color: #91620b;
}





.btn-grad49 {
  background-image: linear-gradient(to right, #fffaec 0%, #f0ba8d  51%, #c5c3ca  100%);
  margin: 0px;
  padding: 19px 23px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 3px;
  display: block;
  font-size: 38px;
}

.btn-grad49:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.backmarquee{
  background-color: #410303;
  color: #ffffff;
}

.flatkrishnagarnadiawb{
background-color: #043d4f;
color: white;
padding: 3px;
}


.buttonhome{
  background-color: rgb(12, 106, 114); 
  border: none;
  color: white;
  padding: 10px 43px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  border-radius: 5px;
}