.buttoncallflat {
    background-color: #04AA6D; /* Green */
    border: none;
    color: white;
    padding: 7px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .buttoncallflats1 {
    background-color: white; 
    color: black; 
    border: 2px solid #494949;
  }

  .buttoncallflat:hover {
    background-color: #a17414;
    color: white;
  }

  .prothamalifespace_flat{
    background-color: #EC1D25;
  }

  .lifespace{
    background-color: #650000;
  }

  .buttoncallflat{
    background-color: rgb(94, 0, 0); 
    border: none;
    color: white;
    padding: 6px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
  }

  .fonts{
    font-size: 14px;
  }

  .closepopupbutton{
    background-color: rgb(12, 106, 114); 
    border: none;
    color: white;
    padding: 6px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 5px;
  }